<template>
    <div class="row mb-5">
        <div class="portada-header" :style="`background-image: url('${require('@/assets/img/img-portada/'+msj.img)}`">
            <div class="container animar">
                <div class="row align-items-end">
                    <div class="container-titulo">
                        <div class="d-inline-block text-uppercase titular  tema-principal">{{ msj.titulo }}</div>
                        <p class="text-uppercase color-principal texto bg-white borde-color-principal">{{ msj.desc }}</p>
                    </div>
                </div>
            </div>
            <img class="rombos" :src="require('@/assets/img/rombos.png')" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'PortadaHeader',
    props: {
        msj: {
            type: Object
        }
    }
}
</script>
<style lang="css">
.portada-header {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 340px;
    overflow: hidden;
}
.portada-header .rombos {
    position: absolute;
    width: 280px;
    right: 0;
    top: 2rem;
}
.portada-header .container .row {
    position: relative;
    height: 340px;
}
.portada-header .container-titulo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    z-index: 3;
}
.portada-header .container-titulo .titular {
    text-transform: uppercase;
    padding: 5px 20px;
    border-top-right-radius: 1.5rem;
    font-size: 1.4rem;
}
.portada-header .container-titulo .texto {
    padding: 12px 20px;
    font-size: 1.65rem;
    max-width: 55%;
    width: auto;
    line-height: 1.4;
    font-weight: bold;
    border-bottom-right-radius: 1.5rem;
    box-shadow: 12px 12px 8px 1px rgba(0, 0, 0, .2);
    margin-left: .75rem;
    position: relative;
    margin-top: .5rem;
}
.portada-header .container-titulo .texto::before {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    border-left: 5px solid;
    top: -.75rem;
    left: -.75rem;
}
.animar {
    animation: fadeInUp 500ms ease-in-out 1 normal both;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@media (max-width: 768px) {
    .portada-header .container-titulo .texto {
        max-width: 100%;
    }
    .portada-header .rombos {
        display: none;
    }
}

</style>

<!--
ruta import
import PortadaHeader from '@/components/global/PortadaHeader.vue'

template
<PortadaHeader :msj="msj"></PortadaHeader>

data {
    msj: {
        titulo: 'titulo',
        desc: 'lorem',
        img: 'foto-1.jpg'
    }
}
components {
    PortadaHeader
}
-->