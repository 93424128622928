<template>
    <section class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <section class="container mt-5">
            <div class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                            <div class="titulo-destacado d-block">
                                <div class="titulos d-block mb-3">
                                    <h5 class="font-weight-bold text-uppercase color-principal">¿Qué ejemplo de encuesta necesitas?</h5>
                                    <h6 class="text-muted">Hemos recopilado nuestros mejores cuestionarios para crear este listado con ejemplos de encuestas, solo tienes que dar clic y utilizarla para tus investigaciones.</h6>
                                </div>
                                <div class="decoracion">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 my-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                            <div class="row">
                                <div class="col-6 col-md-4" v-for="(item, index) of participacion" v-bind:key="index">
                                    <router-link :to="item.url" class="card-participa">
                                        <div class="icono">
                                            <img :src="require(`@/assets/img/iconos/${item.icon}`)" alt="">
                                        </div>
                                        <p>{{ item.title }}</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>