<template src="@/views/participacion/participacion-template.vue"></template>
<style src="@/views/participacion/participacion.css"></style>

<script>
import PortadaHeader from '@/components/global/PortadaHeader.vue'
export default {
    name: 'Participacion',
    data: function() {
        return {
            msj: {
                titulo: 'Encuestas.',
                desc: 'Participacion ciudadana',
                img: 'participacion.jpg'
            },
            participacion: [
                {
                    url: '/',
                    icon: 'icon-participacion-1.png',
                    title: 'Participación para el diagnóstico e identificación de problemas',
                },
                {
                    url: '/entidad/planeacion-y-gestion',
                    icon: 'icon-participacion-2.png',
                    title: 'Planeación y presupuesto participativo',
                },
                {
                    url: '/contactar',
                    icon: 'icon-participacion-3.png',
                    title: 'Consulta ciudadana',
                },
                {
                    url: '/',
                    icon: 'icon-participacion-4.png',
                    title: 'Colaboración e innovación',
                },
                {
                    url: '/ver-transparencia-acceso/informe-rendicion-de-cuenta',
                    icon: 'icon-participacion-5.png',
                    title: 'Rendición de cuentas',
                },
                {
                    url: '/entidad/peticiones-quejas',
                    icon: 'icon-participacion-6.png',
                    title: 'Control social',
                }
            ]
        }
    },
    components: {
        PortadaHeader
    }
}
</script>